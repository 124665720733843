import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

interface UtterancesProps {
    repo: string;
    theme: "github-light" | "github-dark" | "preferred-color-scheme" | "github-dark-orange" | "icy-dark" | "dark-blue" | "photon-dark" | "boxy-light" | "gruvbox-dark";
    "issue-term": string | "pathname" | "url" | "title" | "og:title";
    label?: string;
}
interface UtterancesConfig extends UtterancesProps {
    id: string;
    src: string;
    crossOrigin: string;
    defer: boolean;
}

const Utterances = (props: UtterancesProps) => {
    const utterancesScriptId = "utterances-hgTH9T";
    const {repo, theme, "issue-term": issueTerm = "pathname", label = undefined} = props;
    const ref = useRef<HTMLDivElement | undefined>(undefined);

    const addScriptAttributes = (script: HTMLScriptElement, config: UtterancesConfig) => {
        Object.entries(config).forEach(([key, value]) => {
            script.setAttribute(key, value?.toString() ?? "");
        });
    }
    
    useEffect(() => {
        const utterancesiFrame = document.getElementsByClassName("utterances-frame")[0];
        if (utterancesiFrame) {
            const queryString = [utterancesiFrame.getAttribute("src")];
            Object.entries(props).forEach(([key, value]) => {
                queryString.push(`&${key}=${encodeURIComponent(value)}`);
            });
            utterancesiFrame.setAttribute("src", queryString.join(""));
            return;
        } 
        const script = document.createElement('script');
        const utterancesConfig: UtterancesConfig = {
            id: utterancesScriptId,
            src: 'https://utteranc.es/client.js',
            repo: repo,
            'issue-term': issueTerm,
            label: label,
            theme: theme,
            crossOrigin: 'anonymous',
            defer: true
        };

        // This can get called TWICE if user changes the theme, hence the use of the id check
        if (!document.getElementById(utterancesScriptId)) {
            addScriptAttributes(script, utterancesConfig);
            ref.current?.append(script);
        } else { // theme has changed update script config
            addScriptAttributes(document.getElementById(utterancesScriptId) as HTMLScriptElement, utterancesConfig)
        }
    }, [repo, theme, issueTerm]);

    return <div ref={ref} />;
};

export {Utterances};
