import React, {useEffect, useState} from "react"

import {useStaticQuery, graphql} from "gatsby"

import {CommentsQuery} from "./__generated__/CommentsQuery"
import {WindowLocation} from "@reach/router"
import {Utterances} from "./Utterances";
import store from "../utils/store"

type CommentsProps = { title: string; location: WindowLocation<{}> }
const Comments: React.FC<CommentsProps> = ({title, location}) => {
    const [theme, setTheme] = useState<string | null>(null);
    const data = useStaticQuery<CommentsQuery>(graphql`
        query CommentsQuery {
            site {
                siteMetadata {
                    siteUrl,
                    darkmode,
                    utterances {
                        repo
                        issueTerm
                        label
                    }
                }
            }
        }
    `)

    useEffect(() => {
        setTheme(window.localStorage.getItem("theme"));
        store.on("theme:change", state => {
            if (localStorage.getItem("theme")) {
                setTheme(localStorage.getItem("theme"));
            }
        });
    }, [])

    const isDarkTheme = data.site.siteMetadata.darkmode;

    if (!data.site.siteMetadata.utterances.repo) {
        return null
    }
    const utterancesTheme = (!theme && isDarkTheme) || theme === "1" ? "github-dark" : "github-light";
    return <Utterances repo={data.site.siteMetadata.utterances.repo} theme={utterancesTheme} issue-term="pathname"
                       label={data.site.siteMetadata.utterances.label}/>
}

export default Comments
